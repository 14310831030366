/* community.com */
/* src/App.cs */
.container {
  width: 80%;
  margin: 50px auto;
}

.online {
  color: blue;
}

.offline {
  color: red;
}
